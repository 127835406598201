import React from 'react';
import * as R from 'ramda';
import moment from 'moment';

export const getCountryCode = (locationPath) => {
  if (typeof window !== 'undefined') {
    return locationPath.split('/')[1];
  }
  return '';
};

export const getHomeLink = (path) => {
  const code = getCountryCode(path);
  switch (code) {
    case 'en':
      return '/';
    case 'jp':
      return '/jp';
    case 'zh-hans':
      return '/zh-hans';
    default:
      return '/';
  }
};

export const getLocalisationFromPropertyObject = (object) => {
  switch (object) {
    case 'property_data_en':
      return '';
    case 'property_data_jp':
      return '/jp/';
    case 'property_data_zhhans':
      return '/zh-hans/';
    default:
      return '';
  }
};

export const getLocalisation = (locationPath) => {
  const countryCode = getCountryCode(locationPath);
  if (countryCode === '') {
    return 'en';
  }
  return countryCode;
};

export const getLocalisationUrl = (path) => {
  const countryCode = getLocalisation(path);
  if (countryCode === 'en') {
    return '';
  }
  return countryCode;
};

export const getLocalisedObjectName = (locationPath, objectBaseName) => {
  const countryCode = getCountryCode(locationPath);
  switch (countryCode) {
    case 'jp':
      return `${objectBaseName}_jp`;
    case 'zh-hans':
      return `${objectBaseName}_zhhans`;
    default:
      return objectBaseName;
  }
};

export const isValidValue = (value) => {
  if (value === null || value === '' || value === undefined) {
    return false;
  }
  return true;
};

export const pluralize = (int, singular, plural) => {
  if (parseInt(int) > 1 || parseInt(int) === 0) {
    return `${int} ${plural}`;
  }
  return `${int} ${singular}`;
};

export const buildQueryUrl = (values, hotelIds) => {
  const formattedHotelIds = R.prepend('', hotelIds);
  const mappedHotelIds = formattedHotelIds.reduce(
    (a, b) => `${a}hotelId=${b}&`
  );
  const checkIn = moment(values.checkIn).format('YYYYMMDD');
  const checkOut = moment(values.checkOut).format('YYYYMMDD');
  const baseUrl = 'https://api.roomboss.com/extws/hotel/v1/listAvailable?';

  return `${baseUrl}${mappedHotelIds}checkIn=${checkIn}&checkOut=${checkOut}&numberGuests=${values.guests.value}&rate=ota`;
};

export const chunk = (array, size) => {
  const chunked_arr = [];
  let index = 0;
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index));
    index += size;
  }
  return chunked_arr;
};

export const moneyFormat = (price) =>
  price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

export const ImageOverlay = ({ overlay }) => (
  <div
    className='image-overlay'
    style={{ backgroundColor: `rgba(0,0,0,${overlay / 100})` }}
  />
);


export const propertyGuestOptions = [
  { label: '1', value: 1 },
  { label: '2', value: 2 },
  { label: '3', value: 3 },
  { label: '4', value: 4 },
  { label: '5', value: 5 },
  { label: '6', value: 6 },
  { label: '7', value: 7 },
  { label: '8', value: 8 },
  { label: '9', value: 9 },
  { label: '10', value: 10 },
  { label: '11', value: 11 },
  { label: '12', value: 12 },
];

export const localeOptions = [
  { label: 'English', value: '/' },
  { label: '日本語', value: '/jp/' },
  { label: '简体中文', value: '/zh-hans/' },
];

export const getCorrectPropertyObject = (property, propertyObjectKey) => {
  const object = property[propertyObjectKey] || {};
  const defaultObject = property['property_data_en'] || {};

  const keys = [
    'short_description',
    'description_1',
    'description_2',
    'guests',
    'bedrooms',
    'bathrooms',
    'parking',
    'title',
    'amenities'
  ];

  const blendedObject = keys.reduce((acc, key) => {
    acc[key] = object[key] || defaultObject[key];
    return acc;
  }, {});

  return blendedObject;
};


export const allowWindow = (windowObject) => {
  if (typeof window !== 'undefined') {
    return windowObject;
  }
  return null;
};
