import React from 'react';
import { v4 } from 'uuid';
import { Link } from 'gatsby';

const LinkComponent = ({ linkObject, children, className }) => {
  const checkedLinkObject =
    !!linkObject && !!linkObject.link
      ? linkObject
      : { link: '#', isExternal: false };
  if (linkObject.isExternal) {
    return (
      <a
        key={v4()}
        href={checkedLinkObject.link}
        target='_blank'
        rel='noopener noreferrer'
        className={className}>
        {children}
      </a>
    );
  }
  if (checkedLinkObject.link === '#') {
    return (
      <Link
        to={checkedLinkObject.link}
        onClick={(e) => e.preventDefault()}
        className={`${className} not-link`}>
        {children}
      </Link>
    );
  }
  return (
    <Link to={checkedLinkObject.link} className={className}>
      {children}
    </Link>
  );
};

export default LinkComponent;
